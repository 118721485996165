// @ts-nocheck

import React, { PureComponent, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from "recharts";
import Modal from "../../../../components/Dashboard/Modal";
import { MdOutlineCancel, MdOutlineCancelPresentation } from "react-icons/md";
import { FiMoreVertical, FiTrash2 } from "react-icons/fi";
import { BsFillCircleFill } from "react-icons/bs";
import { classNames, getSvg } from "../../../../utils";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import {
  deleteKPI,
  embedKPI,
  getKPIEmbedStatus,
  getKPIs,
  switchKPIMode,
  uploadKPI,
} from "../../../../api/KPIs";
import { ImSpinner2, ImSpinner6 } from "react-icons/im";
import { AiOutlineInfoCircle } from "react-icons/ai";
import SkeletonLoader from "../../../../components/Dashboard/SkeletonLoader";
import { SET_KPI_EMBED } from "../../../../redux/Utils";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";

const DisplayBoardNew = () => {
  const user: UserProfileModel = useAppSelector((state) => state.user.profile);
  const platform_key = `${user.platformAccountId}&${user.email}`;
  const queryKey = user.queryKey;
  const queryClient = useQueryClient();
  const [newKPI, setNewKPI] = useState("");
  const [updateKPI, setUpdateKPI] = useState("");
  const [showWelcomeModal, setShowWelcomeModal] = useState(false);
  const [showAddKPIModal, setShowAddKPIModal] = useState(false);
  const [showDeleteKPIModal, setShowDeleteKPIModal] = useState(false);
  const [showEditKPIModal, setShowEditKPIModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [currentMetric, setCurrentMetric] = useState<any>({});
  const [isPublished, setPublished] = useState(false);
  const [showLimitModal, setShowLimitModal] = useState(false);
  const [newQuestn, setNewQuestn] = useState("");
  const [updateQuestn, setUpdateQuestn] = useState("");
  const [enableBeacon1, setEnableBeacon1] = useState(true);
  const [archiveStatus, setArchiveStatus] = useState(false);
  const dispatch = useAppDispatch();
  const kpi_embed_obj: KPIEmbedState = useAppSelector(
    (state) => state.utils.kpi_embed
  );
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const posColor = "#729BE1";
  const negColor = "#2960BF";
  const metricPlaceHolder = [
    {
      upload_time: 1718362581.0,
      upload_timestamp: "2024-06-14 10:56:21.799626",
      upload_id: "",
      question_tag: "",
      question: "",
      metric: "",
      map_event: [],
      tag_name: "Success_Metric_Placeholder",
      similarity_threshold: "80%",
      related_reviews_count: 0,
      status: "1",
    },
  ];

  const { status, data, error } = useQuery<any, Error>({
    queryKey: ["getKPIs"],
    queryFn: async () => {
      const response = await getKPIs(queryKey);
      return response.data;
    },
    onSuccess: (data) => {
      // console.log(data);
    },
    select: (data) => {
      const saved = data.Item;
      delete saved.kpi_metrics;
      delete saved.platform_key;
      delete saved.kpi_metadata;
      const newData = Object.values(saved);
      return newData;
    },
  });

  const handleKPIAdd = useMutation({
    mutationFn: async (data: any) => {
      const body = {
        platform_key,
        request_trigger: "0",
        data: [
          {
            tag_name: data.tag_name,
            similarity_threshold: "80%",
            status: data.status,
            metric: data.metric,
            upload_id: uuidv4(),
            upload_time: moment().format(),
            upload_timestamp: moment().format(),
            map_event: [],
            question_tag: data.question_tag,
            question: data.question,
            related_reviews_count: 0,
          },
        ],
      };
      return await embedKPI(body);
    },
    onMutate: async (data) => {
      await queryClient.cancelQueries({ queryKey: ["getKPIs"] });

      const prevData = queryClient.getQueryData(["getKPIs"]);

      const newData = prevData;

      newData.Item[metricPlaceHolder[0].tag_name] = metricPlaceHolder;

      queryClient.setQueryData(["getKPIs"], newData);

      return { prevData };
    },
    onSuccess: () => {
      // setShowAddModal(true);
      dispatch(SET_KPI_EMBED({ status: "Pending", active: true }));
      setShowAddKPIModal(false);
      setNewKPI("");
      // queryClient.invalidateQueries("getKPIs");
      // queryClient.invalidateQueries("getActiveFeedbackQuestions");
      // setNewKPI("");
      // setNewQuestn("");
      // setShowAddModal(false);
    },
    onError: (err, newData, context) => {
      queryClient.setQueryData(["getKPIs"], context.prevData);
    },
    // onSettled: () => {
    //   queryClient.invalidateQueries({ queryKey: ["getKPIs"] });
    // },
  });

  const kpiEmbedListener = useQuery<any, Error>({
    // queryKey: ["getKPIEmbedStatus", kpi_embed_obj.active, kpi_embed_obj.status],
    queryKey: ["getKPIEmbedStatus"],
    queryFn: async () => {
      const response = await getKPIEmbedStatus(queryKey);
      return response.data;
    },
    onSuccess: (data) => {
      // console.log("EMBEDRESPONSE", data);
      if ("Progress_Status" in data) {
        if (data.Progress_Status === "Completed") {
          setNewKPI("");
          setNewQuestn("");
          queryClient.invalidateQueries({ queryKey: ["getKPIs"] });
          queryClient.invalidateQueries({
            queryKey: ["getActiveFeedbackQuestions"],
          });
          dispatch(
            SET_KPI_EMBED({ status: data.Progress_Status, active: false })
          );
          setShowSuccessModal(true);
          return;
        }

        if (data.Progress_Status === "Cancelled") {
          queryClient.invalidateQueries({ queryKey: ["getKPIs"] });
          queryClient.invalidateQueries({
            queryKey: ["getActiveFeedbackQuestions"],
          });
          dispatch(
            SET_KPI_EMBED({ status: data.Progress_Status, active: false })
          );
          setMessage("Sorry an error occurred");
          return;
        }
      }
    },
    enabled: kpi_embed_obj?.active && kpi_embed_obj?.status === "Pending",
  });

  const handleKPIUpdate = useMutation({
    mutationFn: async (data: any) => {
      const body = {
        platform_key,
        request_trigger: "1",
        data: [
          {
            tag_name: data.tag_name,
            similarity_threshold: "80%",
            status: data.status,
            metric: data.metric,
            upload_id: uuidv4(),
            upload_time: moment().format(),
            upload_timestamp: moment().format(),
            map_event: [],
            question_tag: data.question_tag,
            question: data.question,
            related_reviews_count: 0,
          },
        ],
      };
      return await uploadKPI(body);
    },
    onMutate: async (data) => {
      await queryClient.cancelQueries({ queryKey: ["getKPIs"] });

      const prevData = queryClient.getQueryData(["getKPIs"]);

      const newData = prevData;

      newData.Item[currentMetric.tag_name][0].metric = data.metric;

      queryClient.setQueryData(["getKPIs"], newData);

      return { prevData };
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["getKPIs"] });
      queryClient.invalidateQueries({
        queryKey: ["getActiveFeedbackQuestions"],
      });
      // setShowEditKPIModal(false);
      // setUpdateKPI("");
    },
    onError: (err, newData, context) => {
      queryClient.setQueryData(["getKPIs"], context.prevData);
    },
    onSettled: () => {
      setShowEditKPIModal(false);
      setUpdateKPI("");
    },
  });

  const handleKPIDelete = useMutation({
    mutationFn: async (data: any) => {
      const payload = {
        platformKey: queryKey,
        data: [
          {
            archive_request: data.archive_status,
            metricTag: data.tag_name,
            questionTag: data.question_tag,
          },
        ],
      };
      return await deleteKPI(payload);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["getKPIs"] });
      queryClient.invalidateQueries({
        queryKey: ["getActiveFeedbackQuestions"],
      });
      // setShowDeleteKPIModal(false);
    },
    onSettled: () => {
      setShowDeleteKPIModal(false);
    },
  });

  const handleKPISwitch = useMutation({
    mutationFn: async (data: any) => {
      return await switchKPIMode(
        data.status,
        queryKey,
        data.tag_name,
        data.question_tag,
        data.question_tag_status
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["getKPIs"] });
      queryClient.invalidateQueries({
        queryKey: ["getActiveFeedbackQuestions"],
      });
    },
  });

  if (status === "loading")
    return <SkeletonLoader className="rounded-md h-[85vh]" />;

  if (status === "error") return <span>Error: {error.message}</span>;

  if (data) {
    var activeMetrics =
      data.filter((metric: any) => metric[0].status === "1") ?? [];
  }

  // const chartData = [
  //   { name: "Group A", value: 1 },
  //   { name: "Group B", value: 2 },
  //   // { name: "Group C", value: 300 },
  //   // { name: "Group D", value: 200 },
  // ];

  const COLORS = [posColor, negColor];

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  return (
    <>
      <Modal
        show={showAddKPIModal}
        onToggle={() => {
          setShowAddKPIModal(false);
          setNewKPI("");
        }}
      >
        <form
          onSubmit={(e) => {
            e.preventDefault();
            if (newKPI !== "") {
              setMessage("");
              setPublished(true);
              setShowAddKPIModal(false);
              handleKPIAdd.mutate({
                e,
                metric: newKPI,
                tag_name: `Success_Metric_${
                  data.length === 0
                    ? 1
                    : Number(
                        data[data.length - 1][0].tag_name
                          .toString()
                          .replaceAll("Success_Metric_", "")
                      ) + 1
                }`,
                status: "1",
                question_tag: `Question_${
                  data.length === 0
                    ? 1
                    : Number(
                        data[data.length - 1][0].question_tag
                          .toString()
                          .replaceAll("Question_", "")
                      ) + 1
                }`,
                question: newQuestn,
              });
            }
          }}
          className="relative flex flex-col p-6 space-y-2"
        >
          <div className="flex items-center justify-between">
            <h4 className="font-semibold text-gray-800 ">
              Add Business Metrics{" "}
            </h4>
            <MdOutlineCancel
              className="text-gray-700 cursor-pointer"
              size={20}
              onClick={() => {
                setShowAddKPIModal(false);
                setNewKPI("");
              }}
            />
          </div>
          <p className="text-[13px] text-gray-700">
            Track business performance on key areas. Know the pain points of
            your customers and improve on them.
          </p>
          <div className="flex flex-col">
            <label htmlFor="" className="text-[11px] text-gray-800">
              Metric name
            </label>
            <input
              type="text"
              value={newKPI}
              onClick={() => {
                if (enableBeacon1 === true) {
                  setShowWelcomeModal(true);
                }
              }}
              onChange={(e) => setNewKPI(e.target.value)}
              className="border-[1px] border-gray-300 py-2.5 px-2 rounded outline-none"
              required
            />
          </div>
          <div className="flex justify-end">
            <button
              type="submit"
              className="px-4 py-2 text-xs text-white bg-blue-600 rounded"
              disabled={
                (handleKPIAdd.isLoading && isPublished) ||
                (kpi_embed_obj?.active && kpi_embed_obj?.status === "Pending")
              }
            >
              {/* {handleKPIAdd.isLoading ? <ImSpinner2 size={14} className="mx-auto duration-300 ease-linear animate-spin" /> : "Add"} */}
              Add
            </button>
          </div>
        </form>
      </Modal>
      <Modal
        show={showEditKPIModal}
        onToggle={() => setShowEditKPIModal(false)}
      >
        <form
          onSubmit={(e) => {
            e.preventDefault();
            setPublished(false);
            handleKPIUpdate.mutate({
              e,
              metric: updateKPI,
              tag_name: currentMetric.tag_name,
              status: "1",
              question_tag: currentMetric.question_tag,
              question: updateQuestn,
            });
          }}
          className="relative flex flex-col p-6 space-y-2"
        >
          <div className="flex items-center justify-between">
            <h4 className="font-semibold text-gray-800 ">
              Edit Business Metrics
            </h4>
            <MdOutlineCancel
              className="text-gray-700 cursor-pointer"
              size={20}
              onClick={() => {
                setShowEditKPIModal(false);
              }}
            />
          </div>
          <p className="text-[13px] text-gray-700">
            Track business performance on key areas. Know the pain points of
            your customers and improve on them.
          </p>
          <div className="flex flex-col">
            <label className="text-[11px] text-gray-800">Metric name</label>
            <input
              type="text"
              defaultValue={currentMetric.metric}
              onChange={(e) => setUpdateKPI(e.target.value)}
              className="border-[1px] border-gray-300 py-2.5 px-2 rounded outline-none read-only:bg-gray-100"
            />
          </div>
          <div className="flex-col hidden">
            <label className="text-[11px] text-gray-800">Question</label>
            <input
              type="text"
              defaultValue={currentMetric.question}
              onChange={(e) => setUpdateQuestn(e.target.value)}
              className="border-[1px] border-gray-300 py-2.5 px-2 rounded outline-none"
            />
          </div>
          <div className="flex justify-end">
            <button
              type="submit"
              className="w-16 px-4 py-2 text-xs text-white bg-blue-600 rounded"
            >
              {handleKPIUpdate.isLoading ? (
                <ImSpinner2
                  size={16}
                  className="mx-auto duration-300 ease-linear animate-spin"
                />
              ) : (
                "Save"
              )}
            </button>
          </div>
        </form>
      </Modal>
      <Modal
        show={showDeleteKPIModal}
        onToggle={() => {
          setShowDeleteKPIModal(false);
        }}
      >
        <div className="relative flex flex-col p-6 space-y-2">
          <div className="flex items-center justify-between">
            <h4 className="font-semibold text-gray-800 ">
              Delete Business Metrics
            </h4>
            <MdOutlineCancel
              className="text-gray-700 cursor-pointer"
              size={20}
              onClick={() => {
                setShowDeleteKPIModal(false);
              }}
            />
          </div>
          <p className="text-[13px] text-gray-700">
            Track business performance on key areas. Know the pain points of
            your customers and improve on them.
          </p>
          <div className="flex space-x-2.5 justify-end">
            <button
              onClick={() => setShowDeleteKPIModal(false)}
              className="px-4 py-2 text-xs font-semibold text-center text-gray-800 bg-white border rounded"
            >
              Cancel
            </button>
            <button
              onClick={(e) => {
                handleKPIDelete.mutate({
                  e,
                  tag_name: currentMetric.tag_name,
                  archive_status: archiveStatus ? "1" : "0",
                  question_tag: currentMetric.question_tag,
                });
              }}
              className="w-20 px-4 py-2 text-xs text-white bg-red-700 rounded"
            >
              {handleKPIDelete.isLoading ? (
                <ImSpinner2
                  size={13}
                  className="mx-auto duration-300 ease-linear animate-spin"
                />
              ) : (
                "Delete"
              )}
            </button>
          </div>
        </div>
      </Modal>
      <Modal show={showSuccessModal}>
        <div className="relative flex flex-col items-center justify-center p-6 space-y-2.5">
          <div className="mt-2.5 text-center md:mt-5">
            <img src={getSvg("check")} alt="icon" className="mx-auto w-14" />
          </div>
          <h4 className="font-semibold text-center text-gray-800">
            Business Metric Successfully Saved!
          </h4>
          {/* <p className="text-[13px] text-gray-700 text-center">
            Edit your question in the input box of the saved metrics. You{" "}<br />can save it by pressing enter
          </p> */}
          <MdOutlineCancel
            className="absolute text-gray-700 cursor-pointer top-3 right-4"
            size={20}
            onClick={() => setShowSuccessModal((prevState) => !prevState)}
          />
        </div>
      </Modal>
      <div>
        <div className="flex items-center justify-between mb-2">
          <p className="text-sm font-semibold text-gray-800">
            Business metrics
          </p>
          <button
            onClick={() => {
              setShowAddKPIModal(true);
            }}
            className="py-2.5 px-3 rounded bg-blue-600 text-white text-xs disabled:opacity-60 disabled:cursor-default"
            disabled={
              data.length === 4 ||
              (handleKPIAdd.isLoading && isPublished) ||
              (kpi_embed_obj?.active && kpi_embed_obj?.status === "Pending")
            }
          >
            Add new business metric
          </button>
        </div>
        {/* <div className={classNames("flex text-primary font-semibold text-sm items-center px-2.5 py-2 mb-10 rounded-md border border-primary bg-primary/20 w-fit", ((handleKPIAdd.isLoading && isPublished) || (kpi_embed_obj?.active && kpi_embed_obj?.status === "Pending") || (handleKPISwitch.isLoading)) ? "block" : "hidden")}>
          <ImSpinner6 size={14} className="mr-1.5 animate-spin duration-300 ease-linear" />
          {"In progress"}
        </div> */}
        {data.length > 0 && (
          <div className="grid grid-cols-2 gap-4">
            {data.map((metric: any, index: number) => {
              const count =
                metric[0]?.kpi_stats && metric[0]?.kpi_stats[0]
                  ? metric[0]?.kpi_stats[0]
                  : {
                      NEG: 0,
                      POS: 0,
                    };
              // console.log(count)
              const chartData = [
                { name: "NEG", value: count?.NEG },
                { name: "POS", value: count?.POS },
              ];
              const hasNonZeroValue = chartData.some((item) => item.value > 0);
              return metric[0].tag_name === metricPlaceHolder[0].tag_name ? (
                <div
                  className="border border-gray-200 rounded h-[285px] p-3 overflow-hidden"
                  key={index}
                >
                  <div className="animate-pulse">
                    <div className="w-full h-5 bg-gray-100 rounded"></div>
                    <div className="w-full h-5 my-2 bg-gray-100 rounded"></div>
                    <div className="w-1/4 h-5 bg-gray-100 rounded"></div>
                    <div className="flex items-center justify-between h-[185px] my-4 space-x-4">
                      <div className="w-[40%]">
                        <div className="h-3 bg-gray-100 rounded w-[40%]"></div>
                        <div className="h-3 bg-gray-100 rounded w-[40%] my-2"></div>
                        <div className="h-3 bg-gray-100 rounded w-[40%]"></div>
                      </div>
                      <div className="grow">
                        <div className="bg-gray-100 rounded-full w-44 h-44"></div>
                      </div>
                    </div>
                    <div className="w-full h-10 bg-gray-100 rounded"></div>
                  </div>
                </div>
              ) : (
                <div
                  className="border border-gray-200 rounded h-[285px] pt-0 p-2 overflow-auto generalScrollbar"
                  key={index}
                >
                  <div className="flex items-center justify-between">
                    <h4 className="text-sm font-semibold grow text-ellipsis pt-0">
                      {metric[0].metric}
                    </h4>
                    <div className="relative group">
                      <button
                        type="button"
                        className="p-2 transition duration-300 ease-linear rounded-full hover:bg-gray-100"
                      >
                        <FiMoreVertical size={16} className="fill-gray-400" />
                      </button>
                      <div className="absolute top-0 right-0 z-10 hidden w-32 mt-10 origin-top-right bg-white border rounded-md shadow group-focus-within:block shadow-gray-200">
                        <div className="px-1 py-2">
                          <button
                            type="button"
                            onClick={() => {
                              setCurrentMetric(metric[0]);
                              setShowEditKPIModal(true);
                            }}
                            className="px-1.5 py-1.5 w-full text-left font-medium rounded text-xs text-black transition duration-200 ease-linear hover:bg-gray-100"
                          >
                            Edit
                          </button>
                          <button
                            type="button"
                            onClick={() => {
                              setCurrentMetric(metric[0]);
                              setShowDeleteKPIModal(true);
                            }}
                            className="px-1.5 py-1.5 w-full text-left font-medium rounded text-xs text-black transition duration-200 ease-linear hover:bg-gray-100"
                          >
                            Delete
                          </button>
                          <button
                            type="button"
                            onClick={() => {
                              if (
                                Number(metric[0].status) === 0 &&
                                activeMetrics.length === 5
                              ) {
                                setShowLimitModal(true);
                                return;
                              }

                              setCurrentMetric(metric[0]);

                              handleKPISwitch.mutate({
                                tag_name: metric[0].tag_name,
                                status:
                                  Number(currentMetric.status) === 0 ? 1 : 0,
                                question_tag: metric[0].question_tag,
                                question_tag_status:
                                  currentMetric.question !== "" ? "1" : "0",
                              });
                            }}
                            disabled={
                              handleKPISwitch.isLoading &&
                              currentMetric.tag_name === metric[0].tag_name
                            }
                            className="px-1.5 py-1.5 w-full text-left hidden font-medium rounded text-sm text-black transition duration-200 ease-linear hover:bg-gray-100"
                          >
                            {Number(metric[0].status) === 0
                              ? "Activate"
                              : "Deactivate"}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center justify-between my-1.5 text-sm">
                    <p className="px-3 py-2 text-xs font-semibold text-black bg-green-100 rounded-xl w-fit">
                      Threshold: {metric[0].similarity_threshold}
                    </p>
                    <p className="font-medium text-gray-600 hidden">
                      Total Ratings:{" "}
                      <span className="font-semibold text-gray-700">119</span>
                    </p>
                    <Link
                      to={`/feedback-analysis/business-metrics/${metric[0].tag_name
                        .toString()
                        .replaceAll("Success_Metric_", "")}`}
                      className="text-xs font-medium underline text-blueLight underline-offset-1"
                    >
                      Related reviews{" "}
                      <span className="">{`(${metric[0].related_reviews_count})`}</span>
                    </Link>
                  </div>

                  <div className="flex items-center justify-center h-40 my-1.5 space-x-4">
                    <div className="space-y-1 text-left">
                      <span className="text-xs font-semibold text-gray-400">
                        Key:
                      </span>
                      <div className="flex items-center space-x-2">
                        <BsFillCircleFill
                          size={10}
                          className={classNames(
                            // sentiment_tag === "NEG"
                            //   ? "fill-red-600"
                            //   : sentiment_tag === "POS"
                            //   ? "fill-green-700"
                            //   : "fill-orange-500"
                            `fill-[#729BE1]`
                          )}
                        />
                        <span className="text-xs font-normal text-black">
                          Positive
                        </span>
                      </div>
                      <div className="flex items-center space-x-2">
                        <BsFillCircleFill
                          size={10}
                          className={classNames(
                            // sentiment_tag === "NEG"
                            //   ? "fill-red-600"
                            //   : sentiment_tag === "POS"
                            //   ? "fill-green-700"
                            //   : "fill-orange-500"
                            `fill-[#2960BF]`
                          )}
                        />
                        <span className="text-xs font-normal text-black">
                          Negative
                        </span>
                      </div>
                    </div>
                    <div className="block h-full grow">
                      {count.NEG === 0 && count.POS === 0 ? (
                        <div className="flex items-center justify-center">
                          <div className="h-[150px] w-[150px] bg-gray-100 shadow-sm rounded-full"></div>
                        </div>
                      ) : (
                        <ResponsiveContainer width="100%" height="100%">
                          <PieChart width={300} height={300}>
                            <Pie
                              data={chartData}
                              cx="50%"
                              cy="50%"
                              labelLine={false}
                              // label={
                              //   (count.NEG === 0 && count.POS === 0)
                              //     ? null
                              //     : renderCustomizedLabel
                              // }
                              // label
                              outerRadius={80}
                              fill="#8884d8"
                              // innerRadius={hasNonZeroValue ? 60 : 0}
                              // outerRadius={hasNonZeroValue ? 80 : 0}

                              dataKey="value"
                            >
                              {chartData.map((entry, index) => (
                                <Cell
                                  key={`cell-${index}`}
                                  fill={COLORS[index % COLORS.length]}
                                />
                              ))}
                            </Pie>
                          </PieChart>
                        </ResponsiveContainer>
                      )}
                    </div>
                  </div>
                  <p className="text-[11px]">
                    {`There was a total of ${metric[0].related_reviews_count} responses. Your record shows positive response ${count.POS} of 65% and a negative response ${count.NEG}  of 35% indicating the customer experience.`}{" "}
                    {/* <strong>car parking</strong>. */}
                  </p>
                </div>
              );
            })}
          </div>
        )}
        {data.length === 0 && (
          <div className="flex flex-col items-center justify-center py-5 h-[75vh]">
            <p className="text-sm text-gray-600">
              You don&apos;t have any saved business metric yet
            </p>
          </div>
        )}
      </div>
    </>
  );
};

export default DisplayBoardNew;
